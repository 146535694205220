.pointr-category-icon-wrapper {
    border: none !important;
}
.pointr-category-icon {
    width: 48 !important;
    height: 48 !important;
}

#pointr-ui #pointr-logo {
    display: none !important;
    opacity: 0;
}

#pointr-category-list {
    margin: 10px 0px !important;
}

#pointr-ui #pointr-loading {
    background-color: #030912;
  }

#pointr-ui #pointr-loading #pointr-loading-status {
    color: white
}
#pointr-exit-button-none {
    visibility: hidden;
}