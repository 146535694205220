@import 'fonts';
@import 'android';
/* Reset default browser styles */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* Global styles */
body {
  font-family: Arial, sans-serif;
  background-color: #000000;
}

.pointr-container {
  display: flex;
  flex-direction: column;
  z-index: 899;
}


.android-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 9014; /* Just below the loader */
}
.android-loader {
  border: 8px solid grey;
  border-radius: 50%;
  border-top: 8px solid #00BCEB;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
  z-index: 9015;
  /* High z-index to appear above other content in pointr-container */
  position: absolute;
  /* Position relative to pointr-container */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}


@-webkit-keyframes spin {
  from {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg)
  }

  to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg)
  }
}

@keyframes spin {
  from {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg)
  }

  to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg)
  }
}
/*
.poi-popup {
  background-color: white;
  border-radius: 8px;
  padding: 15px;
  width: 250px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.poi-popup-title {
  margin: 0;
  font-size: 18px;
  color: #333;
  margin-bottom: 10px;
}

.poi-popup-description {
  font-size: 14px;
  color: #666;
  line-height: 1.4;
  margin-bottom: 15px;
}

.poi-popup-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  text-align: center;
}

.poi-popup-button:hover {
  background-color: #0056b3;
}

.poi-popup-container {
  max-width: none;
  overflow: hidden;
}*/