$brand-font-folder: "./fonts";
$icon-font-path: "./icons/fonts";
$images-path: "./images";
$flags-image-path: "./images";
@import "./colors.scss";
@import "./common.scss";


$headerHeight: 60px;
$sidebarCollopasedWidth: 0px;
$sidebarExpandedWidth: 0px;

body {
  background-color: $bg-color;
  color: $white;
  padding: .4vw;
}
.App-header {
  min-height: 50vh;
}

#app {
  .app-wrapper {
    .app-content {
      .hideEle {
        display: none;
      }

      .buildingLayout {
        overflow: hidden;
      }
    }
  }
}
