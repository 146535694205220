@font-face {
    font-family: 'CiscoSansTT-Medium';
    src: url('../../public/fonts/Sora-Medium.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'CiscoSansTTLight';
    src: url('../../public/fonts/Sora-Light.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'CiscoSansTTRegular';
    src: url('../../public/fonts/Sora-Regular.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'CiscoSansTTBold';
    src: url('../../public/fonts/Sora-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'CiscoSansTTThin';
    src: url('../../public/fonts/Sora-Thin.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}